import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { loginUser, demoLogin } from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Dropdown, Form } from "react-bootstrap";
import hide from "../../../assets/svg/hide.png";
// import LoginLeftPart from "../../../assets/images/modal.gif";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { APP_CONST } from "../../../config/const";
import { checkIfDemoUserExist } from "../../../redux/app/actions";
import MumbaiSports from "../../../assets/images/icons/mumbaisports.png";

const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { appDetails } = useSelector((state) => state.app);
  const [isDemoUserExist, setIsDemoUserExist] = useState(false);
  useEffect(() => {
    checkDemoUser();
  }, []);
  const checkDemoUser = async () => {
    const response = await checkIfDemoUserExist();
    if (response && response?.data?.error === false) {
      setIsDemoUserExist(response?.data?.data?.hasDemoUsers === true);
    }
  };
  const handleDemoLogin = () => {
    dispatch(demoLogin());
  };
  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      let loginData = { ...formData };
      handleClose();
      dispatch(loginUser(loginData));
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      className={"steps-canvas login-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
        closeVariant="white"
      >
        <Modal.Title>Log in. It is fast and easy.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="login-section">
          <div className="Login-rightPart">
            <div className="form-steps">
              <Form>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Username*"
                    name="username"
                    onChange={handleChange}
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="formBasicPassword"
                >
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password*"
                    name="password"
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <span
                    className="icon"
                    onClick={() => setShowPassword((s) => !s)}
                  >
                    {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors?.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Login
                </Button>
                {(isDemoUserExist && (
                  <Button
                    variant="primary"
                    onClick={handleDemoLogin}
                    className=" mt-2"
                  >
                    Login with Demo Id
                  </Button>
                )) ||
                  null}
                <div className="d-flex justify-content-end mb-3">
                  <a
                    className="form-link"
                    onClick={() => {
                      handleClose();
                      handleModalShow(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD);
                    }}
                  >
                    Forgot Password
                  </a>
                </div>
              </Form>

              <div className="sign-up mt-4">
                Not Registered?
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    handleClose();
                    handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
                  }}
                >
                  Create an account
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;
