import React, { useEffect, useState } from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import { Container, Col, Row } from "react-bootstrap";

import PaymentLogo1 from "../assets/images/payments/visa.png";
import PaymentLogo2 from "../assets/images/payments/mastercard.png";
import PaymentLogo3 from "../assets/images/payments/Skrill.png";
import PaymentLogo4 from "../assets/images/payments/paytm.png";
import PaymentLogo5 from "../assets/images/payments/googlepay.png";

import ProviderLogo1 from "../assets/images/providers/evolution.jpg";
import ProviderLogo2 from "../assets/images/providers/ezugi.jpg";
import ProviderLogo3 from "../assets/images/providers/spribe.png";
import ProviderLogo4 from "../assets/images/providers/netent.png";
import ProviderLogo5 from "../assets/images/providers/redtiger.png";
import ProviderLogo6 from "../assets/images/providers/Paragmatic.png";
import ProviderLogo7 from "../assets/images/providers/ProviderImg7.jpg";
import PaymentLogo6 from "../assets/images/payments/UPI.png";
import PaymentLogo7 from "../assets/images/payments/phonepe.png";

import HomeIcon from "../assets/images/icons/homeicon.png";
import MenuIcon from "../assets/images/icons/menuicon.png";
import LiveCasinoIcon from "../assets/images/icons/live-casino-icon.svg";
import CasinoIcon from "../assets/images/icons/casino-icon.svg";
import AviatorIcon from "../assets/images/icons/aviator-icon.svg";
import ExchangeIcon from "../assets/images/icons/exchange-icon.svg";
import SportsIcon1 from "../assets/images/icons/Sports-white.png";
import LogInIcon from "../assets/images/icons/login-white.png";
import SupportIcon from "../assets/images/icons/Support-white.png";
import PaymentMethod from "../assets/images/payment-method.png";
import Age18 from "../assets/images/age18.png";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import XPGIcon from "../assets/images/icons/poker-chip.png";
import ModalWrapper from "./ModalWrapper";
import { APP_CONST } from "../config/const";

import EzugiImg from "../assets/images/providers/ezugi-img.png";
import EvolutionImg from "../assets/images/providers/evolution-img.png";
import SpribeImg from "../assets/images/providers/spribe-img.png";
import NetentImg from "../assets/images/providers/netent-img.png";
import PragmaticImg from "../assets/images/providers/pragmatic-img.png";
import RedTigerImg from "../assets/images/providers/red-tiger-img.png";

import Calling2 from "../assets/images/icons/telephone-call.png";

const BeforeLoginFooter = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const { isAuth } = useSelector((state) => state.auth);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [defaultModal, setShowDefaultModal] = useState(
    APP_CONST.AUTH_MODAL.LOGIN
  );

  const [showToggle, setShowToggle] = useState(false);
  const htmlElement = document.documentElement;
  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
      htmlElement.style.overflow = "hidden";
    } else {
      document.body.classList.remove("header_open");
      htmlElement.style.overflow = "auto";
    }
  }, [showToggle]);
  // useEffect(() => {
  //   setShowToggle(window.screen.width < 992);
  // }, []); //condition will revert below this device width

  const footerPages = ["menu", "home", "crash", "sports", "casino", "/"];
  useEffect(() => {
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const handleAuthRedirection = (navigationUrl) => {
    if (isAuth) {
      navigate(navigationUrl);
    } else {
      setShowAuthModal(true);
    }
  };

  const handleModalShow = (modalName) => {
    setShowAuthModal(true);
    setShowDefaultModal(modalName);
  };

  return (
    <footer className="footer">
      <Container>
        <div className="providers_sec d-none">
          <Row>
            <Col md={6} xs={12}>
              <div className="provider-mob-border">
                <div className="heading mb-4">
                  <div className="text">
                    <h5>Top Providers</h5>
                    <p>
                      Play Your favorite Casino Games with{" "}
                      <span>Top Providers</span>
                    </p>
                  </div>
                </div>

                <div className="providers_logo d-flex flex-wrap justify-content-center">
                  <a href="/casino/evolution" className="logo_box">
                    <img src={ProviderLogo1} alt="Provider Logo" />
                  </a>
                  <a href="/casino/pragmatic" className="logo_box">
                    <img src={ProviderLogo2} alt="Provider Logo" />
                  </a>
                  <a href="casino/ezugi" className="logo_box">
                    <img src={ProviderLogo3} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={ProviderLogo4} alt="Provider Logo" />
                  </a>
                  <a href="/casino/onetouch" className="logo_box">
                    <img src={ProviderLogo5} alt="Provider Logo" />
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="">
                <div className="heading payment-heading mb-4 ">
                  <div className="text">
                    <h5>Payment Gateway</h5>
                    <p>
                      Accept Process &amp; disburse{" "}
                      <span>Digital Payments </span>
                      for your bussiness
                    </p>
                  </div>
                </div>

                <div className="providers_logo payment_logo d-flex flex-wrap justify-content-center">
                  <a href="/casino/evolution" className="logo_box">
                    <img src={PaymentLogo1} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/pragmatic"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo2} alt="Provider Logo" />
                  </a>
                  <a href="casino/ezugi" className="logo_box">
                    <img src={PaymentLogo3} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={PaymentLogo4} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/onetouch"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo5} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={PaymentLogo6} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/onetouch"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo7} alt="Provider Logo" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="d-none">
          <div className="providers_img">
            <a href="#">
              <img src={RNG} loading="lazy" alt="RNG Certified" />
            </a>
            <a href="#">
              <img src={Gambling} loading="lazy" alt="Gambling Commission" />
            </a>
            <a href="#">
              <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
            </a>
            <a href="#">
              <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
            </a>
          </div>

          <div className="footer-links">
            <ul>
              <li>
                <a href="/contactuss">Contact Us</a>
              </li>
              <li>
                <a href="/privacypolicys ">Privacy Policy</a>
              </li>
              <li>
                <a href="/responsiblegames">Responsible Gaming</a>
              </li>
              <li>
                <a href="/fairplays">Fair Play</a>
              </li>
              <li>
                <a href="/gamesrules">Games Rules</a>
              </li>
              <li>
                <a href="/termss">Terms and Conditions</a>
              </li>
            </ul>
          </div>

          <div className="helpline d-none">
            <a
              className="panel"
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "#"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              WhatsApp Us
            </a>
            {/* <a href="tel:{user?.mobileno}">{user?.mobileno}</a> */}
          </div>

          <div className="d-flex justify-content-center mt-2 d-none">
            <img
              src={PaymentMod}
              loading="lazy"
              style={{ width: "250px" }}
              alt="Payment Otions"
            />
          </div>

          <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
            <img
              src={Betfair}
              loading="lazy"
              className="mb-3  mx-3 betfair"
              alt="Betfair Logo"
            />
            <a href="#">
              <img
                src={Gamble18}
                loading="lazy"
                alt="Gamble Responsible"
                className="mb-3 betfair"
              />
            </a>
          </div>

          <p>{appDetails?.FOOTER_TEXT || ""}</p>
          <div className="paymentImages d-none">
            <ul>
              <li>
                <img src="./images/sewa.jpeg" />
              </li>
              <li>
                <img src="./images/khalti.jpeg" />
              </li>
            </ul>
          </div>

          <div className="social-icons d-none">
            <ul>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? appDetails?.WHATS_APP_URL
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  {" "}
                  <img src={Whatsapp} alt="Whatsapp Icon" />
                  {/* <span className="notification-count">2</span> */}
                </a>
              </li>
              <li>
                <a
                  href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={Telegram} alt="Telegram Icon" />
                </a>
              </li>
              <li>
                <a
                  href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                  }
                  target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={Instagram} alt="Instagram Icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="CallingSection">
          <a href={`tel://${appDetails?.PHONE}`}>
            <img src={Calling2} alt="calling" />
            <p>+91{appDetails?.PHONE}</p>
          </a>
        </div>

        <div className="our-provider">
          <h5>Our Game Provider</h5>

          <div className="providers_logo">
            <a
              href="#"
              // onClick={() => handleAuthRedirection("/casino/evolution")}
              className="logo_box"
            >
              <img src={EzugiImg} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box">
              <img src={EvolutionImg} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box">
              <img src={SpribeImg} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box">
              <img src={NetentImg} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box">
              <img src={PragmaticImg} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box">
              <img src={RedTigerImg} alt="Provider Logo" />
            </a>
          </div>
        </div>

        <div className="payment-methods">
          <h5>Available Payment Methods</h5>

          <div className="img-box">
            <img src={PaymentMethod} alt="Provider Logo" />
          </div>
        </div>

        <div className="foot-text">
          <img src={appDetails?.LOGO_URL} alt="Logo" />
          <img src={Age18} alt="age 18+" />
          <p>
            In order to register for this website, the user is required to
            accept the <a href="/termss">General Terms and Conditions</a>. In
            the event the General Terms and Conditions are updated, existing
            users may choose to discontinue using the products and services
            before the said update shall become effective, which is a minimum of
            two weeks after it has been announced.
          </p>
        </div>

        <div className="footer-section d-none">
          <div className="footer-right-part">
            <div className="providers_img">
              <a href="#">
                <img src={RNG} loading="lazy" alt="RNG Certified" />
              </a>
              <a href="#">
                <img src={Gambling} loading="lazy" alt="Gambling Commission" />
              </a>
              <a href="#">
                <img
                  src={ResponsibleG}
                  loading="lazy"
                  alt="Responsible Gaming"
                />
              </a>
              <a href="#">
                <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
              </a>
            </div>
          </div>
          <div className="footer-left-part">
            <div className="footer-menu-web">
              <div>
                <h3>SOLUTIONS</h3>
                <ul>
                  <li>
                    <a href="/disputeresolutiions">Dispute Resolution</a>
                  </li>

                  <li>
                    <a href="/payouts">Payout</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3>SUPPORT</h3>
                <ul>
                  <li>
                    <a href="/responsiblegames">Responsible Gaming</a>
                  </li>
                  <li>
                    <a href="/selfexclusion">Self Exclusion</a>
                  </li>

                  <li>
                    <a href="/registration">Registration</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-menu-web">
              <div>
                <h3>COMPANY</h3>
                <ul>
                  <li>
                    <a href="/contactuss">Contact US</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3>LEGAL</h3>
                <ul>
                  <li>
                    <a href="/privacypolicys">Privacy</a>
                  </li>
                  <li>
                    <a href="/termss">Terms</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right d-none">
          <p>
            Copyright © 2024 <span>Iplay52 </span> | All rights reserved.
          </p>
        </div>
      </Container>

      <div className="foot-menu">
        <ul>
          <li>
            <a href="/registration">Help</a>
          </li>
          <li>
            <a href="/privacypolicys">Privacy Policy</a>
          </li>
          <li>
            <a href="/termss">Terms & Conditions</a>
          </li>
        </ul>
      </div>

      <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
        <ul className="d-flex m-0 p-0 list-unstyled">
          {/* <li>
              <a className="active" href="/sign-in">
                <img src={HomeIcon} />
                <span>Home</span>
              </a>
            </li> */}
          <li>
            <a
              // className="homeactive"
              href="#"
              onClick={() => setShowToggle((s) => !s)}
            >
              <img src={MenuIcon} />
              <span>Menu</span>
            </a>
          </li>
          <li>
            <a href="/" className={activePage == "/" ? "active" : ""}>
              <img src={LiveCasinoIcon} />
              <span>Live Casino</span>
            </a>
          </li>
          <li>
            <a
              href="/casino"
              className={activePage == "casino" ? "active" : ""}
            >
              <img src={CasinoIcon} />
              <span>Casino</span>
            </a>
          </li>
          <li>
            <a href="/crash" className={activePage == "crash" ? "active" : ""}>
              <img src={AviatorIcon} />
              <span>Crash</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              onClick={() => {
                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={ExchangeIcon} />
              <span>Exchange</span>
            </a>
          </li>
          {/* <li>
            <a
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "#"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              <img src={SupportIcon} />
              <span>Support</span>
            </a>
          </li> */}
        </ul>
      </div>
      {showAuthModal && (
        <ModalWrapper
          defaultShow={APP_CONST.AUTH_MODAL.LOGIN}
          handleClose={setShowAuthModal}
        />
      )}
    </footer>
  );
};

export default BeforeLoginFooter;
